.page-body {
  width: 100vw;
  height: 100vh;
  position: relative;
  text-align: center;
  background: linear-gradient(90deg, #7f7fd5, #86a8e7, #91eae4);
}
.page-body::before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.page-body .main-login {
  display: inline-block;
  vertical-align: middle;
  min-width: 260px;
  width: 400px;
  margin: -80px auto 0 auto;
  padding: 70px 60px;
  text-align: left;
  background-color: #fff;
}
.page-body .main-login .header {
  vertical-align: top;
  border-style: none;
  margin-bottom: 20px;
  text-align: center;
}
.page-body .main-login .header .logo {
  height: 50px;
  vertical-align: top;
  border-style: none;
  margin-bottom: 20px;
}
.page-body .main-login .header .logo .login-login {
  border-radius: 50%;
}
.page-body .main-login .header .header-title {
  font-size: 18px;
  color: #6c7293;
  font-family: Microsoft YaHei, Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  position: relative;
  margin: 0;
  text-align: center;
}
.page-body .main-login .login-form:deep(.login-input) {
  border: none;
  background: rgba(235, 237, 242, 0.4);
  border-radius: 5px;
  font-size: 14px;
}
.page-body .main-login .login-form .varify-from-item {
  display: flex;
}
.page-body .main-login .login-form .auth-code-input {
  width: 150px;
}
.page-body .main-login .login-form .input-prefix {
  color: #d9d9d9;
}
.page-body .main-login .login-form .varify-image {
  width: 120px;
  height: 35px;
  margin-left: 10px;
}
.page-body .main-login .login-form:deep(.login-input .ant-input) {
  border: none;
  background: none;
  border-radius: 5px;
  font-size: 14px;
}
.page-body .main-login .login-form .login-error {
  margin-bottom: 20px;
}
.page-body .main-login .login-form .login-button {
  width: 100%;
}
