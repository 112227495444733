
.page-body {
  width: 100vw;
  height: 100vh;
  position: relative;
  text-align: center;
  // background-image: url("~@/assets/img/login-bg.jpg");
  background: linear-gradient(90deg, #7f7fd5, #86a8e7, #91eae4);

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  .main-login {
    display: inline-block;
    vertical-align: middle;
    min-width: 260px;
    width: 400px;
    margin: -80px auto 0 auto;
    padding: 70px 60px;
    text-align: left;
    // box-shadow: 0 0 2px 4px #f1f1f1;
    background-color: #fff;

    .header {
      vertical-align: top;
      border-style: none;
      margin-bottom: 20px;
      text-align: center;

      .logo {
        height: 50px;
        vertical-align: top;
        border-style: none;
        margin-bottom: 20px;

        .login-login {
          border-radius: 50%;
        }
      }

      .header-title {
        font-size: 18px;
        color: #6c7293;
        font-family: Microsoft YaHei, Avenir, Helvetica Neue, Arial, Helvetica,
          sans-serif;
        position: relative;
        margin: 0;
        text-align: center;
      }
    }

    .login-form {
      &:deep(.login-input) {
        border: none;
        background: rgba(235, 237, 242, 0.4);
        border-radius: 5px;
        font-size: 14px;
      }

      .varify-from-item {
        display: flex;
      }
      .auth-code-input {
        width: 150px;
      }
      .input-prefix {
        color: #d9d9d9;
      }
      .varify-image {
        width: 120px;
        height: 35px;
        margin-left: 10px;
      }

      &:deep(.login-input .ant-input) {
        border: none;
        background: none;
        border-radius: 5px;
        font-size: 14px;
      }

      .login-error {
        margin-bottom: 20px;
      }

      .login-button {
        width: 100%;
      }
    }
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;